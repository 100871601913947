<template>
    <div>
        <h2>基本信息</h2>
        <img v-select-file="upload" :src="url + userinfo.imageurl" alt="" style="width:100px;height:100px;">
        <div>
            <el-form class="form" :label-position="labelPosition" label-width="80px" :model="userinfo">
                <div class="input">
                    <el-form-item label="ID">
                        <el-input v-model="userinfo.id" disabled></el-input>
                    </el-form-item>
                </div>
                <div class="input">
                    <el-form-item label="账号">
                        <el-input v-model="userinfo.user" disabled></el-input>
                    </el-form-item>
                </div>
                <div class="input">
                    <el-form-item label="昵称">
                        <el-input v-model="userinfo.username"></el-input>
                    </el-form-item>
                </div>
                <div class="input">
                    <el-form-item label="密码">
                        <el-input type="password" v-model="password" placeholder="密码不填不修改"></el-input>
                    </el-form-item>
                </div>
            </el-form>

            <div class="input">
                <el-button type="primary" @click="update()">更新</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { ImageUpload } from '@/utils/common.js'
export default {
    data() {
        return {
            labelPosition: 'right',
            token: '',
            userinfo: {},
            password: '',
            url: 'https://bill.5ymdr.com/'
        }
    },
    created() {
        this.getuers()
    },
    methods: {
        async upload(event) {
            let arr = Array.from(event.target.files).map(async (file) => {
                try {
                    let result = await ImageUpload(file)
                    // let url = "http://101.200.128.203:9712" + result.data
                    let url = result.data
                    return url
                } catch (err) {
                    console.error(err)
                    return null
                }
            })
            let result = []
            for (let index = 0; index < arr.length; index++) {
                let val = await arr[index]
                if (val) {
                    result.push(val)
                }
            }
            this.userinfo.imageurl = result[0].data.url
            event.target.type = 'text';
            event.target.value = '';
            event.target.type = 'file';
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    alert('submit!');
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        getuers() {
            this.token = localStorage.getItem("token")
            if (this.token == "" || this.token == undefined) {
                this.$message.error("未登录账号");
                this.$router.push({ path: '/' })
            }
            // console.log(localStorage.getItem("token"))
            this.axios.get(`https://bill.5ymdr.com/php/me/getusers.php?token=${this.token}`).then((resp) => {
                let res = resp.data
                // console.log(res)
                if (res.code == 200) {
                    this.userinfo = res.data
                    console.log(this.userinfo.level)
                } else {
                    this.$message.error(res.msg);
                    this.$router.push({ path: '/' })
                }
            })
        },
        update() {
            this.axios.get(`https://bill.5ymdr.com/php/me/name.php?username=${this.userinfo.username}&token=${this.token}`).then((resp) => {
                let res = resp.data
                // console.log(res)
                if (res.code != 200) {
                    this.$message.error(res.msg);
                }
            })
            if (this.password != "") {
                this.axios.get(`https://bill.5ymdr.com/php/me/lock.php?newspassword=${this.password}&token=${this.token}`).then((resp) => {
                    let res = resp.data
                    console.log(res)
                    if (res.code == 200) {
                        this.$message({
                            message: '密码修改成功',
                            type: 'success'
                        });
                        this.$router.go(0)
                    } else if (res.code == 201) {
                        this.$message.error(res.msg);
                    } else if (res.code == 403) {
                        this.$router.push({ path: '/' })
                    }
                })
            }
            this.axios.get(`https://bill.5ymdr.com/php/admin/UploadAvatar.php?imageurl=${this.userinfo.imageurl}&token=${this.token}`).then((resp) => {
                let res = resp.data
                if (res.code == 200) {
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                } else if (res.code != 200) {
                    this.$message.error(res.msg);
                }
            })
            this.$router.go(0)
        }
    }
}
</script>

<style lang="less" scoped>
img {
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, .16);
    border: 8px solid #eee;
    transition: all 1s;
    width: 100px;
    height: 100px;
}

.form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.input {
    width: 550px;
    margin-top: 30px;
    transition: all 0.5s;
}

@media screen and (max-width:1800px) {
    .input {
        width: 550px;
        transition: all 0.5s;
    }
}

@media screen and (max-width:1480px) {
    .input {
        width: 450px;
        transition: all 0.5s;
    }
}
</style>