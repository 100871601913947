import Vue from 'vue'

const change = (event) => {
    let callBack = window.selectFile.change || (() => { })
    callBack(event)
}

const selectClick = () => {
    window.selectFile.el.click()
}

const updateComponent = (el, bind) => {
    window.selectFile = window.selectFile || {}
    let selectFileObj = window.selectFile
    let keys = el.getAttributeNames()
    /** 上传多个文件 */
    if (keys.includes('files')) {
        selectFileObj.el.multiple = true
    }
    el.removeEventListener('click', selectClick, false)
    el.addEventListener('click', selectClick, false)
    selectFileObj.change = bind.value
}
Vue.directive('select-file', {
    update(el, bind, vnode) {
        updateComponent(el, bind)
    },
    inserted: function (el, bind) {
        window.selectFile = window.selectFile || {}
        let selectFileObj = window.selectFile
        if (!selectFileObj.el) {
            let file = window.document.createElement('input')
            file.type = 'file'
            file.addEventListener('change', change, false)
            file.style.display = "none"
            window.document.body.appendChild(file)
            selectFileObj.el = file
        }
        updateComponent(el, bind)
    }
})