<template>
    <body>
        <div class="view">
            <div class="menu">
                <Menu></Menu>
            </div>
            <div class="content">
                <Content></Content>
            </div>
        </div>
        <div class="box"></div>
    </body>
</template>
  
<script>
import Menu from './Menu/Menu.vue';
import Content from './Content/Content.vue';

export default {
    name: 'index',
    components: {
        Menu,
        Content
    },
    data() {
        return {};
    },
    created() {

    },
    methods: {
    }
}
</script>
<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
}

.view {
    transition: transform 1s ease;
    border-radius: 20px;
    padding: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.3);
    width: 1480px;
    height: 1000px;
    display: flex;
    transition: all 1s;

    .menu {
        width: 300px;
        height: 100%;
        // background-color: rgba(255, 255, 255, 0.1);
        border-radius: 20px;
        position: fixed;
        transition: all 1s;
        left: 0;
        top: 0;
    }

    .content {
        flex: 1;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 10px;
        padding-left: 270px;
    }

    h3 {
        font-weight: 900;

        button {
            border: none;
            margin-bottom: 20px;
            background-color: rgba(255, 255, 255, 0);
        }
    }
}

@media screen and (max-width:1800px) {
    .view {
        width: 1280px;
        height: 800px;
        padding: 20px;

        .menu {
            width: 200px;
            height: 100%;
            transition: all 1s;
        }

        .content {
            padding-left: 180px;
            height: 780px;
        }

        h3 {
            font-size: 14px;
        }
    }
}


@media screen and (max-width:1480px) {
    .view {
        width: 1080px;
        height: 650px;

        h3 {
            font-size: 14px;
        }

        .content {
            padding-left: 180px;
            height: 630px;
        }
    }
}

@media screen and (max-width:1280px) {
    .view {
        width: 1080px;
        height: 600px;

        h3 {
            font-size: 14px;
        }

        .content {
            padding-left: 180px;
            height: 580px;
        }
    }
}

.view:hover {
    transform: translate(-50%, -51%);
    transition: transform 1s ease;
}

.box {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background-image: url('../../public/background1.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    z-index: -1;
}
</style>
  