<template>
    <body id="poster">
        <div class="login">
            <el-form class="formlist" :label-position="labelPosition" label-width="0px" :model="formLabelAlign">
                <h3>
                    MOLOCK后台管理系统
                    <el-button @click="toregister">点我注册</el-button>
                </h3>
                <el-form-item label="">
                    <el-input type="text" v-model="formLabelAlign.Loginname" placeholder="账号"></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-input type="password" v-model="formLabelAlign.Loginpassword" placeholder="密码"></el-input>
                </el-form-item>
                <el-button type="primary" style="width:100%;background:#505458;" @click="Login">登录</el-button>
                <el-switch v-model="value" inactive-text="记住密码" style="margin-top:20px;"></el-switch>
            </el-form>
        </div>
        <div class="box"></div>
    </body>
</template>
  
<script>

export default {
    name: 'Login',
    components: {

    },
    data() {
        return {
            labelPosition: 'right',
            formLabelAlign: {
                Loginname: '',
                Loginpassword: ''
            },
            value: false
        };
    },
    created() {
        this.initialization()
    },
    methods: {
        Login() {
            this.axios.get(`https://bill.5ymdr.com/php/me/login.php?user=${this.formLabelAlign.Loginname}&password=${this.formLabelAlign.Loginpassword}`).then((resp) => {
                let res = resp.data
                if (res.code == 200) {
                    this.$message({
                        message: '欢迎进入MOLOCK后台管理系统',
                        type: 'success'
                    });
                    if (this.value) {
                        localStorage.setItem("user", this.formLabelAlign.Loginname)
                        localStorage.setItem("password", this.formLabelAlign.Loginpassword)
                    }
                    localStorage.setItem("token", res.data)
                    this.$router.push({ path: '/Index' })
                } else if (res.code == 201) {
                    this.$message.error(res.msg);
                }
            })
        },
        toregister() {
            this.$router.push({ path: '/Register' })
        },
        initialization() {
            this.formLabelAlign.Loginname = localStorage.getItem("user")
            this.formLabelAlign.Loginpassword = localStorage.getItem("password")
        }
    }
}
</script>
<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
}

.login {
    transition: transform 1s ease;
    border-radius: 20px;
    padding: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.7);
    width: 400px;
    height: 250px;

    h3 {
        font-weight: 900;

        button {
            border: none;
            margin-bottom: 20px;
            background-color: rgba(255, 255, 255, 0);
        }
    }
}

.login:hover {
    transform: translate(-50%, -52%);
    transition: transform 1s ease;
}

@media screen and (max-width:700px) {
    .login {
        width: 300px;
        height: 250px;

        h3 {
            font-size: 14px;
        }
    }
}

.box {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background-image: url('../../public/background.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    z-index: -1;
}
</style>
  