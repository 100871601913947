<template>
    <div class="contents">
        <div class="Menu">
            <!-- <el-button type="primary" @click="Add">添加分类</el-button> -->
            <el-input v-model="keyword" placeholder="请输入用户ID" prefix-icon="el-icon-search" clearable v-if="level == 1"
                @change="search"></el-input>
            <h3 v-if="level == 0">账单管理</h3>
        </div>
        <el-checkbox-group v-model="checkList" @change="handleCheckedCitiesChange">
            <el-table :data="Billlist" class="table" border stripe>
                <el-table-column label="选择" width="65" align="center">
                    <el-checkbox :label="scope.$index + 1" :key="scope.row.id" slot-scope="scope"></el-checkbox>
                </el-table-column>
                <el-table-column :label="level == 0 ? '账单ID' : '用户ID'" width="80" align="center">
                    <div slot-scope="scope"> {{ level == 0 ? Billlist[scope.$index].id : Billlist[scope.$index].userid }}
                    </div>
                </el-table-column>
                <el-table-column prop="title" label="类型" width="100" align="center"></el-table-column>
                <el-table-column prop="expenditure" label="收支" width="100" align="center"></el-table-column>
                <el-table-column prop="amount" label="金额" width="100" align="center"></el-table-column>
                <el-table-column prop="WhichDay" label="日期" width="150" sortable align="center"></el-table-column>
                <el-table-column prop="remarks" label="备注" align="center"> </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                        <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="block">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-sizes="[10, 20, 40, 60, 80, 100]" :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="this.total">
                </el-pagination>
            </div>
            <el-dialog title="账单详情" :visible.sync="dialogFormVisible" :append-to-body=true>
                <el-form :model="form">
                    <el-form-item label="金额" :label-width="formLabelWidth">
                        <el-input v-model="form.amount" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="备注" :label-width="formLabelWidth">
                        <el-input v-model="form.remarks" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="类型" :label-width="formLabelWidth">
                        <el-select v-model="form.type" placeholder="选择分类">
                            <el-option :label="item.title" :value="item.title" v-for="item in typelist"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="updata">确 定</el-button>
                </div>
            </el-dialog>
        </el-checkbox-group>
    </div>
</template>

<script>
export default {
    created() {
        this.GetBill()
    },
    data() {
        return {
            path: '',
            title: '',
            token: '',
            Billlist: [],
            dialogFormVisible: false,
            form: {},
            formLabelWidth: '200',
            typelist: [],
            level: '',
            currentPage: 1,
            total: 1,
            pageSize: 20,
            checked: false,
            checkList: [],
            DropRows: '',
            keyword: ''
        };
    },
    methods: {
        search() {
            const keyword = this.keyword;
            this.GetBill()
        },
        handleCheckedCitiesChange(e) {
            console.log(e.map(index => this.Billlist[index - 1].id).join(","));
            this.DropRows = e.map(index => this.Billlist[index - 1].id).join(",")
            console.log(this.checkList);
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.GetBill()
        },
        // 分页功能
        handleCurrentChange(val) {
            this.currentPage = val
            this.GetBill()
        },
        //更新数据
        updata() {
            this.dialogFormVisible = false
            // console.log(this.form)
            this.axios.get(`https://bill.5ymdr.com/php/admin/Lockmx.php?id=${this.form.id}&amount=${this.form.amount}&remarks=${this.form.remarks}&type=${this.form.type}&token=` + this.token).then((resp) => {
                console.log(resp)
                let res = resp.data.data
                if (res) {
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                }
            })
        },
        //打开编辑
        handleEdit(index, row) {
            // console.log(index, row);
            this.dialogFormVisible = true
            this.form = row
        },
        //询问是否删除
        handleDelete(index, row) {
            if (this.checkList.length <= 1) {
                this.$confirm('确定要删除此数据吗？？')
                    .then(_ => {
                        this.deletes(row)
                    })
                    .catch(_ => { });
            } else {
                this.$confirm('确定要' + this.checkList.length + '条删除数据吗？？')
                    .then(_ => {
                        this.deleteRows()
                    })
                    .catch(_ => { });
            }
        },
        // 执行删除
        deletes(row) {
            console.log(row)
            this.axios.get(`https://bill.5ymdr.com/php/admin/DropBill.php?id=${row.id}&token=` + this.token).then((resp) => {
                console.log(resp)
                let res = resp.data
                if (res.code == 200) {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.Billlist = this.Billlist.filter(item => { return item != row })
                } else if (res.code == 201) {
                    this.$message.error(res.msg);
                }
            })
        },
        // 删除多条数据
        deleteRows() {
            var row = []
            this.checkList.forEach((i, s) => {
                row.push(this.Billlist[i - 1])
            })

            this.axios.get(`https://bill.5ymdr.com/php/admin/DropBill.php?id=${this.DropRows}&token=` + this.token).then((resp) => {
                console.log(resp)
                let res = resp.data
                if (res.code == 200) {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.checkList.forEach((i, s) => {
                        this.Billlist = this.Billlist.filter(item => { return item != row[s] })
                    })
                    this.checkList = []
                } else if (res.code == 201) {
                    this.$message.error(res.msg);
                }
            })
        },
        handleClose() {
            this.$confirm('确认关闭？')
                .then(_ => { })
                .catch(_ => { });
        },
        GetBill() {
            //查询该用户是否为管理员
            this.level = localStorage.getItem("level")
            this.token = localStorage.getItem("token")
            this.axios.get(`https://bill.5ymdr.com/php/admin/Bill.php?page=${this.currentPage}&pageSize=${this.pageSize}&id=${this.keyword}&token=` + this.token).then((resp) => {
                let res = resp.data
                // console.log(res);
                if (res.code == 200) {
                    this.Billlist = res.data.data
                    this.total = res.data.total
                    this.Billlist.forEach((i, s) => {
                        this.Billlist[s].id = Number(this.Billlist[s].id)
                        if (this.Billlist[s].expenditure == 0) {
                            this.Billlist[s].expenditure = '收入'
                        } else {
                            this.Billlist[s].expenditure = '支出'
                        }
                    })
                } else if (res.code == 201) {
                    this.$message.error(res.msg);
                } else if (resp.code == 403) {
                    this.$router.push({ path: '/' })
                }
                // console.log(res)
            })
            this.axios.get(`https://bill.5ymdr.com/php/typelist/getlist.php`).then((resp) => {
                let res = resp.data
                this.typelist = res.data
                // console.log(this.typelist)
            })
        }
    }
}
</script>

<style lang="less" scoped>
.Menu {
    height: 50px;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 0 30px;
}

img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, .16);
    border: 3px solid #eee;
}

.level {
    width: 100px;
    margin: 0 auto;
    font-size: 75%;
    font-weight: 900;
    color: #fff;
    border-radius: 0.25em;
    padding: 8px 15px;
}

.orange {
    background-color: #f0ad4e;
}

.contents {
    background-color: #fff;
}

.green {
    background-color: rgb(0, 172, 132);
}

.contents {
    max-height: 1000px;
    display: inline-block;
    width: 1210px;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: scroll;
    transition: all 1s;
}

@media screen and (max-width:1800px) {
    .contents {
        height: 770px;
        width: 1100px;
        transition: all 1s;
    }
}

@media screen and (max-width:1480px) {
    .contents {
        height: 640px;
        width: 900px;
        transition: all 1s;
    }
}

@media screen and (max-width:1280px) {
    .contents {
        height: 580px;
        transition: all 1s;
    }
}

.contents {
    background-color: rgba(255, 255, 255, 0.1);
}
</style>