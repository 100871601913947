<template>
    <div class="Header" v-html="this.title">
    </div>
</template>

<script>
export default {
    created() {
        this.Getpath()
    },
    data() {
        return {
            path: '',
            title: ''
        };
    },
    watch: {
        $route() {
            this.Getpath()
        }
    },
    methods: {
        Getpath() {
            this.path = this.$router.currentRoute.path
            if (this.path == '/Index') {
                this.title = 'MOLOCK后台管理系统&nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;&nbsp;个人中心'
            } else if (this.path == '/Bill') {
                this.title = 'MOLOCK后台管理系统&nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;&nbsp;系统管理&nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;&nbsp;账单管理'
            } else if (this.path == '/UserInfo') {
                this.title = 'MOLOCK后台管理系统&nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;&nbsp;系统管理&nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;&nbsp;用户管理'
            } else if (this.path == '/Type') {
                this.title = 'MOLOCK后台管理系统&nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;&nbsp;系统管理&nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;&nbsp;类型管理'
            } else if (this.path == '/FeedBack') {
                this.title = 'MOLOCK后台管理系统&nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;&nbsp;意见反馈管理'
            }
        }
    }
}
</script>

<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
}

.Header {
    position: fixed;
    top: 0;
    font-size: 14px;
    font-weight: 900;
    line-height: 30px;
    width: 500px;
    text-align: left;
    margin-left: 50px;
}

@media screen and (max-width:1920px) {
    .Header {
        margin-left: 30px;
        line-height: 20px;
        font-size: 10px;
    }
}

@media screen and (max-width:1480px) {
    .Header {
        margin-left: 20px;
    }
}
</style>