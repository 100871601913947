<template>
    <div class="contents">
        <el-table :data="Billlist" class="table" border stripe>
            <el-table-column label='用户ID' width="80" align="center">
                <div slot-scope="scope"> {{ Billlist[scope.$index].UserID }}</div>
            </el-table-column>
            <el-table-column prop="FID" label="FID" width="100" align="center"></el-table-column>
            <el-table-column prop="Rid" label="Rid" width="100" align="center"></el-table-column>
            <el-table-column prop="createtime" label="日期" width="160" sortable align="center"></el-table-column>
            <el-table-column prop="content" label="内容" align="center"> </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                    <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block" v-if="total > 20">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                :page-sizes="[10, 20, 40, 60, 80, 100]" :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="this.total">
            </el-pagination>
        </div>
        <el-dialog title="评论详情" :visible.sync="dialogFormVisible" :append-to-body=true>
            <el-form :model="form">
                <el-form-item label="内容" :label-width="formLabelWidth">
                    <el-input v-model="form.content" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="updata">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    created() {
        this.GetBill()
    },
    data() {
        return {
            path: '',
            title: '',
            token: '',
            Billlist: [],
            dialogFormVisible: false,
            form: {},
            formLabelWidth: '200',
            level: '',
            currentPage: 1,
            total: 1,
            pageSize: 20,
            checked: false,
            checkList: [],
            DropRows: ''
        };
    },
    methods: {
        handleSizeChange(val) {
            this.pageSize = val
            this.GetBill()
        },
        // 分页功能
        handleCurrentChange(val) {
            this.currentPage = val
            this.GetBill()
        },
        //更新数据
        updata() {
            this.dialogFormVisible = false
            console.log(this.form);
            this.axios.get(`https://bill.5ymdr.com/php/admin/LockComment.php?id=${this.form.ID}&content=${this.form.content}&token=` + this.token).then((resp) => {
                console.log(resp)
                let res = resp.data.data
                if (res) {
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                }
            })
        },
        //打开编辑
        handleEdit(index, row) {
            // console.log(index, row);
            this.dialogFormVisible = true
            this.form = row
        },
        //询问是否删除
        handleDelete(index, row) {
            this.$confirm('确定要删除此数据吗？？')
                .then(_ => {
                    this.deletes(row)
                })
                .catch(_ => { });
        },
        // 执行删除
        deletes(row) {
            console.log(row)
            this.axios.get(`https://bill.5ymdr.com/php/admin/DropComment.php?id=${row.ID}&token=` + this.token).then((resp) => {
                console.log(resp)
                let res = resp.data
                if (res.code == 200) {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.Billlist = this.Billlist.filter(item => { return item != row })
                } else if (res.code == 201) {
                    this.$message.error(res.msg);
                }
            })
        },
        handleClose() {
            this.$confirm('确认关闭？')
                .then(_ => { })
                .catch(_ => { });
        },
        GetBill() {
            //查询该用户是否为管理员
            this.level = localStorage.getItem("level")
            this.token = localStorage.getItem("token")
            this.axios.get(`https://bill.5ymdr.com/php/admin/comment.php?page=${this.currentPage}&pageSize=${this.pageSize}&token=` + this.token).then((resp) => {
                let res = resp.data
                // console.log(res);
                if (res.code == 200) {
                    this.Billlist = res.data.data
                    this.total = res.data.total
                    console.log(this.Billlist);
                } else if (res.code == 201) {
                    this.$message.error(res.msg);
                } else if (resp.code == 403) {
                    this.$router.push({ path: '/' })
                }
                // console.log(res)
            })
        }
    }
}
</script>

<style lang="less" scoped>
.el-table::before {
    height: 0;
}

.orange {
    background-color: #f0ad4e;
}

.contents {
    background-color: #fff;
}

.green {
    background-color: rgb(0, 172, 132);
}

.contents {
    max-height: 1000px;
    display: inline-block;
    width: 1210px;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: scroll;
    transition: all 1s;
}

@media screen and (max-width:1800px) {
    .contents {
        height: 770px;
        width: 1100px;
        transition: all 1s;
    }
}

@media screen and (max-width:1480px) {
    .contents {
        height: 640px;
        width: 900px;
        transition: all 1s;
    }
}

@media screen and (max-width:1280px) {
    .contents {
        height: 580px;
        transition: all 1s;
    }
}


.table {
    background-color: rgba(255, 255, 255, 0.1);
}

.block {
    background-color: #fff;
}
</style>