<template>
    <div class="contents">
        <div class=" Menu">
            <el-input v-model="keyword" placeholder="请输入用户ID" prefix-icon="el-icon-search" clearable v-if="level == 1"
                @change="search"></el-input>
            <h3 v-if="level == 0">用户管理</h3>
        </div>
        <el-table :data="Userinfolist" class="table" border stripe>
            <el-table-column prop="id" label="ID" sortable width="100" align="center"></el-table-column>
            <el-table-column label="头像" width="100" align="center">
                <img :src="url + Userinfolist[scope.$index].imageurl" alt="" slot-scope="scope">
            </el-table-column>
            <el-table-column prop="user" label="账号" width="140" align="center"></el-table-column>
            <el-table-column prop="username" label="用户名" width="130" align="center"></el-table-column>
            <el-table-column prop="openid" label="OpenID" align="center"></el-table-column>
            <el-table-column label="权限" width="120" align="center">
                <div slot-scope="scope" :class="Userinfolist[scope.$index].level == 0 ? 'level orange' : 'level green'">
                    {{ Userinfolist[scope.$index].level == 0 ? 'VIP会员' : '系统管理员' }}
                </div>
            </el-table-column>
            <el-table-column label="操作" align="center" v-if="level == 1">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                    <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                </template>
            </el-table-column>
            <!-- <el-table-column label="详情" align="center" v-if="level == 0">
                <template>
                    <el-button size="mini" @click="ShowUserinfo()">查看</el-button>
                </template>
            </el-table-column> -->
        </el-table>

        <div class="block">
            <!-- <span class="demonstration">直接前往</span> -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                :page-sizes="[20, 40, 60, 80, 100]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
                :total="this.total">
            </el-pagination>
        </div>

        <el-dialog title="用户信息" :visible.sync="dialogFormVisible" :append-to-body=true>
            <el-form :model="form">
                <el-form-item label="昵称" :label-width="formLabelWidth">
                    <el-input v-model="form.username"></el-input>
                </el-form-item>
                <el-form-item label="账号" :label-width="formLabelWidth">
                    <el-input v-model="form.user"></el-input>
                </el-form-item>
                <el-form-item label="Openid" :label-width="formLabelWidth">
                    <el-input v-model="form.openid" disabled></el-input>
                </el-form-item>
                <el-form-item label="权限" :label-width="formLabelWidth">
                    <el-select v-model="form.level" placeholder="选择分类">
                        <el-option :label="item.title" :value="item.level" v-for="item in typelist"
                            :key="item.id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="updata">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    created() {
        this.level = localStorage.getItem("level")
        this.token = localStorage.getItem("token")
        this.GetUserinfo()
    },
    data() {
        return {
            path: '',
            title: '',
            token: '',
            Userinfolist: [],
            dialogFormVisible: false,
            form: {},
            formLabelWidth: '200',
            typelist: [
                {
                    level: "1",
                    title: "系统管理员"
                },
                {
                    level: "0",
                    title: "VIP会员"
                }
            ],
            url: 'https://bill.5ymdr.com/',
            level: 0,
            currentPage: 1,
            total: 1,
            pageSize: 20,
            keyword: ''
        };
    },
    methods: {
        search() {
            const keyword = this.keyword;
            this.GetUserinfo()
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.GetUserinfo()
        },
        // 分页功能
        handleCurrentChange(val) {
            this.currentPage = val
            this.GetUserinfo()
        },
        //更新数据
        updata() {
            this.dialogFormVisible = false
            console.log(this.form)
            this.axios.get(`https://bill.5ymdr.com/php/admin/LockUser.php?id=${this.form.id}&user=${this.form.user}&username=${this.form.username}&level=${this.form.level}&token=` + this.token).then((resp) => {
                console.log(resp)
                let res = resp.data
                if (res.code == 200) {
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                } else if (res.code == 201) {
                    this.$message.error(res.msg);
                }
            })
        },
        //打开编辑
        handleEdit(index, row) {
            console.log(index, row);
            this.dialogFormVisible = true
            this.form = row
        },
        //点击删除
        handleDelete(index, row) {
            // console.log(index, row);
            this.$confirm('确定要删除吗？？')
                .then(_ => {
                    this.$confirm('删除后将无法恢复')
                        .then(_ => {
                            this.deletes(row)
                        })
                        .catch(_ => { this.cancel() });
                })
                .catch(_ => { this.cancel() });
        },
        cancel() {
            this.$message({
                message: '取消成功',
                type: 'success'
            });
        },
        deletes(row) {
            // console.log('确认删除')
            this.axios.get(`https://bill.5ymdr.com/php/admin/DropUser.php?id=${row.id}&token=` + this.token).then((resp) => {
                console.log(resp)
                let res = resp.data
                if (res.code == 200) {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.Userinfolist = this.Userinfolist.filter(item => { return item != row })
                } else if (res.code == 201) {
                    this.$message.error(res.msg);
                } else if (resp.code == 403) {
                    this.$router.push({ path: '/' })
                }
            })
        },
        ShowUserinfo(index, row) {
            this.$message.error('非管理员权限');
        },
        GetUserinfo() {
            this.axios.get(`https://bill.5ymdr.com/php/admin/Userinfo.php?page=${this.currentPage}&id=${this.keyword}&pageSize=${this.pageSize}&token=` + this.token).then((resp) => {
                let res = resp.data
                console.log(res)
                if (res.code == 200) {
                    this.Userinfolist = res.data.data
                    this.total = res.data.total
                } else {
                    this.$message.error(res.msg);
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.Menu {
    height: 50px;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 0 30px;
}

img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, .16);
    border: 3px solid #eee;
}

.level {
    width: 100px;
    margin: 0 auto;
    font-size: 75%;
    font-weight: 900;
    color: #fff;
    border-radius: 0.25em;
    padding: 8px 15px;
}

.orange {
    background-color: #f0ad4e;
}

.contents {
    background-color: #fff;
}

.green {
    background-color: rgb(0, 172, 132);
}

.contents {
    max-height: 1000px;
    display: inline-block;
    width: 1210px;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: scroll;
    transition: all 1s;
}

@media screen and (max-width:1800px) {
    .contents {
        height: 770px;
        width: 1100px;
        transition: all 1s;
    }
}

@media screen and (max-width:1480px) {
    .contents {
        height: 640px;
        width: 900px;
        transition: all 1s;
    }
}

@media screen and (max-width:1280px) {
    .contents {
        height: 580px;
        transition: all 1s;
    }
}


.table {
    background-color: rgba(255, 255, 255, 0.1);
}
</style>