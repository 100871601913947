import axios from 'axios'
export const ImageUpload = (file) => {
    const token = localStorage.getItem("token")
    let params = new FormData()
    params.append("file", file)
    return axios.post(`https://bill.5ymdr.com/php/admin/Upload.php?token=` + token, params, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}